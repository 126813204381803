<script setup lang="ts">
const props = defineProps<{ num: number }>();

const splittedNum = computed(() => props.num.toString().split(''));
</script>

<template>
  <div class="number-formatter__wrapper inline-block">
    <div
      class="number-formatter__inner inline-block bg-gradient-to-b from-[#6A77E0] to-[#3A417A] box-decoration-clone font-bold leading-none"
    >
      <span
        v-for="(number, i) in splittedNum"
        :key="i"
        class="number-formatter__inner-item align-top font-din italic text-yellow"
      >
        {{ number }}
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.number-formatter__wrapper {
  display: inline-block;
  max-width: 100%;
}
.number-formatter__inner {
  font-size: calcMinSp(83);
  padding-inline: calcMinSp(16);
  clip-path: polygon(
    calcMinSp(30) 0%,
    100% 0%,
    calc(100% - calcMinSp(30)) 100%,
    0% 100%
  );
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  /*IE(Internet Explorer)・Microsoft Edgeへの対応*/
  -ms-overflow-style: none;
  /*Firefoxへの対応*/
  scrollbar-width: none;
}
/*Google Chrome、Safariへの対応*/
.number-formatter__inner::-webkit-scrollbar {
  display: none;
}

.number-formatter__inner-item {
  padding-inline: calcMinSp(9);
  margin-right: calcMinSp(8);
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 120%;
    background-color: rgb(var(--color-white));
    position: absolute;
    transform: rotate(20.5deg);
    left: 0;
    top: -10%;
  }
  &:first-child::after {
    display: none;
  }
}
</style>
